import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("./Pages/Home"));
const Tarifs = lazy(() => import("./Pages/Tarifs")); // Import the Tarifs component
const Order = lazy(() => import("./Pages/Order")); // Import the Contact component
const Header = lazy(() => import("./components/Header/index"));
const Footer = lazy(() => import("./components/Footer/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <GlobalStyle />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tarifs" element={<Tarifs />} /> {/* Add the Tarifs route */}
          <Route path="/order" element={<Order />} /> {/* Update to use element prop */}
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;